const networkConfig = {
    apiKey: process.env.REACT_APP_MYHEALTH_GATEWAY_API_TOKEN,
    psViewerURL: 'https://myhealth-ps-viewer.dev.myhealth.gnb-doh.vsfcloud.com/',
    fhirDocumentURL: 'https://dev.myhealth.gnb-doh.vsfcloud.com/fhir-document-apis/v1/fhir/Patient/',
    idQuery: '<id>/$summary?profile=<profile>',
    identifierQuery: '$summary?identifier=<identifier>&profile=<profile>',
    defaultProfile: 'https://hl7.org/fhir/uv/ips/StructureDefinition-Bundle-uv-ips.html',
    psIdListUrl: 'https://gitlab.com/verosource/verosource-wiki/-/wikis/Connectathon/IPS-Sample-Patient-IDS',
};
export default networkConfig;
